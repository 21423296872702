var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"institution-pane"}},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-wrap flex-shrink-1",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"d-flex flex-column mid-container-full"},[_c('div',{staticClass:"dash-side-container dash-side-container-topNavbar"},[_c('ToolBar',{attrs:{"title":_vm.$route.meta.pageTitle,"balanceLoading":_vm.isBalanceLoading,"creditBalance":_vm.creditBalance},on:{"topUpDialog":function($event){return _vm.open('payment')}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"loading":_vm.pageLoading,"small":"","color":"accent","icon":"","fab":""},on:{"click":function($event){_vm.$store.dispatch('sms/listSenderID'),
                      _vm.$store.dispatch('sms/pendingSenderID')}}},on),[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("cloud_done")])])]}}])},[_c('span',{staticClass:"ft font-weight-medium"},[_vm._v("Get Latest Changes")])])]},proxy:true}])})],1),_c('v-row',{staticClass:"container-view",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[(!_vm.internetState)?_c('div',{staticClass:"d-flex flex-row flex-wrap",staticStyle:{"width":"100%","overflow-y":"auto"}},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1 flex-wrap"},[_c('div',{staticClass:"center-container d-flex flex-column flex-grow-1 flex-shrink-1 mt-5 animate__animated animate__fadeIn",staticStyle:{"padding":"0px !important"}},[_c('h2',{staticClass:"ft font-weight-medium pa-1 text-center"},[_vm._v(" Sender IDs Management ")]),_c('p',{staticClass:"ft font-weight-medium px-2 text-center"},[_vm._v(" Manage multiple sender IDs here. Create and update sender IDs, broadcast sms notification to your contacts or contact groups by using different approved sender IDs. ")]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 flex-shrink-1 mt-5"},[_c('p',{staticClass:"pt-3"},[_c('span',{staticClass:"ft pl-2 font-weight-medium text-md text-uppercase py-2"},[_vm._v("Approved Sender IDs")]),_c('v-btn',{staticClass:"ml-2 mt-n1 float-right",attrs:{"icon":"","color":"primary","loading":_vm.isLoading},on:{"click":function($event){$event.stopPropagation();return _vm.$store.dispatch('sms/listSenderID', {
                          isApproved: true,
                        })}}},[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("refresh")])])],1),(_vm.pageLoading)?_c('SkeletonPreloader',{attrs:{"count":2,"height":"10vh","width":"auto"}}):[(_vm.approvedSenderIDlist.length > 0)?_vm._l((_vm.approvedSenderIDlist),function(senderId){return _c('v-card',{key:senderId.id,staticClass:"box-shadow mx-2 pa-3 mt-1",staticStyle:{"border-radius":"8px"}},[_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 ft font-weight-bold",staticStyle:{"align-items":"center"}},[_vm._v(" "+_vm._s(senderId.name)+" ")]),_c('div',{staticClass:"d-flex flex-row",staticStyle:{"align-items":"center"}},[_c('v-chip',{staticClass:"mr-3",attrs:{"small":"","color":"rgba(65, 253, 77, 0.41)","label":""}},[_c('span',{staticClass:"ft text-uppercase font-weight-bold text-sm",staticStyle:{"color":"rgb(7, 143, 60)"}},[_vm._v("Approved")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.open(
                                      'delete',
                                      senderId.id,
                                      'sms/senderIDDetails'
                                    )}}},on),[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("delete")])])]}}],null,true)},[_c('span',{staticClass:"ft font-weight-medium"},[_vm._v("Delete Sender ID")])])],1)])])}):_c('EmptyPage',{attrs:{"image":require('@/assets/images/sender-id.png'),"imageWidth":"120px","title":"No Approved Sender IDs","subTitle":"Once a sender ID is approved, you can use it to broadcast sms notifications"}})]],2),_c('div',{staticClass:"d-flex flex-column flex-shrink-1 flex-grow-1",staticStyle:{"min-height":"520px","overflow-y":"scroll"}},[_c('p',{staticClass:"pt-3"},[_c('span',{staticClass:"ft pl-2 font-weight-medium text-md text-uppercase py-2"},[_vm._v("Pending Sender IDs")]),_c('v-btn',{staticClass:"ml-2 mt-n1 float-right",attrs:{"icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.$store.dispatch('sms/pendingSenderID', {
                          isApproved: false,
                        })}}},[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("refresh")])])],1),(_vm.pageLoading)?_c('SkeletonPreloader',{attrs:{"height":"10vh","count":2,"width":"auto"}}):[(_vm.pendingSenderIDList.length > 0)?_vm._l((_vm.pendingSenderIDList),function(senderID){return _c('v-card',{key:senderID.uuid,staticClass:"box-shadow mx-2 pa-3 mt-1",staticStyle:{"border-radius":"8px"}},[_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 ft font-weight-bold",staticStyle:{"align-items":"center"}},[_vm._v(" "+_vm._s(senderID.name)+" ")]),_c('div',{staticClass:"d-flex flex-row",staticStyle:{"align-items":"center"}},[_c('v-chip',{staticClass:"mr-3",attrs:{"small":"","color":"rgba(253, 171, 65, 0.41)","label":""}},[_c('span',{staticClass:"ft text-uppercase font-weight-bold text-sm",staticStyle:{"color":"rgb(174, 101, 5)"}},[_vm._v("Pending")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.open(
                                      'delete',
                                      senderID.id,
                                      'sms/senderIDDetails'
                                    )}}},on),[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("delete")])])]}}],null,true)},[_c('span',{staticClass:"ft font-weight-medium"},[_vm._v("Delete Sender ID")])])],1)])])}):_c('EmptyPage',{attrs:{"image":require('@/assets/images/clock.svg'),"imageWidth":"120px","title":"No Pending Sender IDs","subTitle":"All pending sender ids will show up here"},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('v-btn',{staticClass:"mt-4",attrs:{"color":"primary","depressed":""},on:{"click":function($event){$event.stopPropagation();return _vm.open('senderId')}}},[_c('span',{staticClass:"ft font-weight-medium text-capitalize font-size-sm"},[_vm._v("Add Sender ID")])])]},proxy:true}],null,false,1533236706)})]],2)])]),_c('v-fab-transition',[(_vm.pendingSenderIDList.length > 0)?_c('v-btn',{staticStyle:{"bottom":"75px","right":"70px"},attrs:{"color":"primary","dark":"","fixed":"","bottom":"","right":"","fab":""},on:{"click":function($event){return _vm.open('senderId')}}},[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("send_to_mobile")])]):_vm._e()],1)],1):_c('InternetConnection')],1)],1)],1)]),_c('AddSMSSenderID',{attrs:{"state":_vm.dialog('senderId'),"loading":_vm.isLoading},on:{"actions":_vm.close}}),_c('DeleteDialog',{attrs:{"state":_vm.dialog('delete'),"message":'Once you delete this Sender ID, this operation cannot be undone.',"loading":_vm.isLoading},on:{"actions":_vm.close,"delete":_vm.deleteSenderID}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }