










































































































































































































































































































import Component, { mixins } from 'vue-class-component';
import WidgetMixins from '../../../mixins/WidgetMixins';
import DialogMixins from '../../../mixins/DialogMixins';
import { Getter, namespace } from 'vuex-class';
import { ISenderID } from '@/types/types';
import { loadWidget } from '@/utils/helpers';

const smsModule = namespace('sms');
@Component({
  name: 'Index',
  components: {
    InternetConnection: loadWidget('pages/InternetConnection'),
    ToolBar: loadWidget('widgets/ToolBar'),
    SnackBar: loadWidget('widgets/SnackBar'),
    EmptyPage: loadWidget('pages/EmptyPage'),
    ProgressBar: loadWidget('widgets/CircularProgressLoader'),
    DeleteDialog: loadWidget('widgets/DeleteDialog'),
    AddSMSSenderID: loadWidget(`widgets/AddSenderID`),
    SkeletonPreloader: loadWidget('widgets/SkeletonPreloader'),
  },
  filters: {},
})
export default class Index extends mixins(WidgetMixins, DialogMixins) {
  @Getter('getCreditBalance') creditBalance!: number;
  @smsModule.Getter('getSenderIDList') approvedSenderIDlist!: Array<
    ISenderID & { id: number; slug: string; uuid: string }
  >;
  @smsModule.Getter('getPendingSenderIDList') pendingSenderIDList!: Array<
    ISenderID & { id: number; slug: string; uuid: string }
  >;

  deleteSenderID(): void {
    const payload = this.$store.getters['sms/getSenderIDDetails'];
    this.$store.dispatch('sms/deleteSenderID', payload);
  }

  created(): void {
    this.$store.dispatch('sms/pendingSenderID');
    this.$store.dispatch('sms/listSenderID');
  }
}
